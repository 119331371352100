import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import VoiceRecorder from './voiceRecorder/VoiceRecorder';

const QuestionTrigger = props => {
    const [question, setQuestion] = useState(props.question);
    const [audio, setAudio] = useState(props.audio ? props.audio : undefined);

    function handleDelete(e) {
        e.stopPropagation();
        props.onDelete(props.questionId);
        fetch('/tree/delete-question/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({question_id: props.questionId}),
        });
    };

    function handleChange(e) {
        setQuestion(e.currentTarget.value);
    }

    function handleVoiceChange(val) {
        setQuestion(val);
        handleQuestionChange(val);
    }

    function handleQuestionChange(val) {
        props.enableTrigger();
        const v = typeof val === "string" ? val : question;
        fetch('/tree/update-question/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({question_id: props.questionId, value: v}),
        });
    }

    function handleAudioChange(val) {
        setAudio(val);
        let formData = new FormData();
        formData.append(props.questionId, val);
        fetch('/tree/update-question-audio/', {
            method: 'POST',
            headers: { 
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: formData,
        });
    }

    return (
        <Form.Group as={Row} className="mb-0">
            <Col sm="10" className="pr-0">
                <Form.Control 
                    className="NodeViewer-textarea" 
                    type="text" 
                    value={question} 
                    onClick={e => {e.stopPropagation()}}
                    onChange={handleChange}
                    onBlur={handleQuestionChange} 
                    onFocus={props.disableTrigger}
                />
            </Col>
            <Col sm="1" className="pr-0 d-flex align-items-center">
                <VoiceRecorder audio={audio} onTextChange={handleVoiceChange} onAudioChange={handleAudioChange}/>
            </Col>
            <Col sm="1" className="pl-0 d-flex align-items-center">
                <FontAwesomeIcon 
                    className="cursor-pointer" 
                    icon={faTrashAlt} 
                    size="lg" 
                    onClick={handleDelete}
                    style={{color: '#d7d7d7'}}
                />
            </Col>

        </Form.Group>
    );
}

export default QuestionTrigger;
