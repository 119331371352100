import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Satisfaction from './Satisfaction';
import VoiceRecorder from './voiceRecorder/VoiceRecorder';

const Answer = props => {
    const [answer, setAnswer] = useState(props.answer);
    const [audio, setAudio] = useState(props.audio ? props.audio : undefined);

    function handleDelete(e) {
        e.stopPropagation();
        props.onDelete(props.questionId, props.answerId);
        fetch('/tree/delete-answer/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({answer_id: props.answerId}),
        });
    };

    function handleChange(e) {
        setAnswer(e.currentTarget.value);
    }

    function handleVoiceChange(val) {
        setAnswer(val);
        handleAnswerChange(val);
    }

    function handleAudioChange(val) {
        setAudio(val);
        let formData = new FormData();
        formData.append(props.answerId, val);
        fetch('/tree/update-answer-audio/', {
            method: 'POST',
            headers: { 
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: formData,
        });
    }

    function handleAnswerChange(val) {
        const v = typeof val === "string" ? val : answer;
        fetch('/tree/update-answer/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({answer_id: props.answerId, value: v}),
        });
    }

    function handleSatisfactionChange(value) {
        fetch('/tree/update-satisfaction/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({answer_id: props.answerId, value: value}),
        });
    }

    return (
        <Form.Group as={Row} className="mb-0">
            <Col sm="8" className="pl-5">
                <Form.Control 
                    className="NodeViewer-textarea-light mb-1" 
                    type="text" 
                    value={answer} 
                    onClick={e => {e.stopPropagation()}}
                    onChange={handleChange}
                    onBlur={handleAnswerChange} 
                />
            </Col>
            <Col sm="1" className="pr-0 pl-0 d-flex align-items-center">
                <VoiceRecorder 
                    audio={audio}
                    onTextChange={handleVoiceChange} 
                    onAudioChange={handleAudioChange}
                />
            </Col>
            <Col sm="2" className="pr-0 d-flex align-items-center">
                <Satisfaction satisfaction={props.satisfaction} onChange={handleSatisfactionChange}/>
            </Col>
            <Col sm="1" className="pl-0 d-flex align-items-center">
                <FontAwesomeIcon 
                    className="cursor-pointer" 
                    icon={faTrashAlt} 
                    size="lg" 
                    onClick={handleDelete}
                    style={{color: '#d7d7d7'}}
                />
            </Col>

        </Form.Group>
    );
}

export default Answer;
