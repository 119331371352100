export const startListening = () => {
    return {
        type: 'START_LISTENING',
    };
};

export const stopListening = () => {
    return {
        type: 'STOP_LISTENING',
    };
};
