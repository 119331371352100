import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MicRecorder from 'mic-recorder-to-mp3';
import MicButton from './MicButton';
import PlayButton from './PlayButton';
import * as listeningActions from '../../redux/actions/ListeningActions';

let SpeechRecognition;
if (SpeechRecognition) SpeechRecognition = SpeechRecognition;
else SpeechRecognition = window.webkitSpeechRecognition;

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const recognition = new SpeechRecognition();

recognition.continous = true;
recognition.interimResults = true;
recognition.lang = 'pl-PL';

const VoiceRecorder = props => {
    const anyListening = useSelector((state) => state.listening.listening);
    const dispatch = useDispatch();

    const [listening, setListening] = useState(false);
    const [micListening, setMicListening] = useState(false);
    const [interim, setInterim] = useState('');
    const [final, setFinal] = useState('');
    const [blobURL, setBlobURL] = useState(props.audio ? URL.createObjectURL(props.audio) : '');
    const isInitialMount = useRef(true);

    const toggleListen = e => {
        e.stopPropagation();
        if (anyListening && !listening) {
            alert('Wyłącz obecne nagrywanie!');
            return;
        }
        dispatch(listeningActions.startListening());
        let isListening = !listening;
        setListening(isListening);
    };

    useEffect(() => {
        if (isInitialMount.current) isInitialMount.current = false;
        else handleListen();
    }, [listening]);

    const handleListen = () => {
        setFinal('');
        setInterim('');
        console.log('listening?', listening);
    
        if (listening) {
            recognition.start();
            Mp3Recorder.start();
            setMicListening(true);
            recognition.onend = () => {
                console.log('...continue listening...');
                recognition.start();
            }
        } else {
            Mp3Recorder.stop().getMp3().then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob);
                setBlobURL(blobURL);
                props.onAudioChange(blob);
                recognition.stop();
                dispatch(listeningActions.stopListening());
                setMicListening(false);
            });
        }

        recognition.onend = () => {
            console.log('Stopped listening per click');
        }
    
        recognition.onstart = () => {
            console.log('Listening!');
        }
    
        recognition.onresult = event => {
            for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    const fin = final + transcript + '?';
                    setFinal(fin);
                    props.onTextChange(fin);
                }
                else setInterim(interim + transcript);
            }
        }
                
        recognition.onerror = event => {
            console.log("Error occurred in recognition: " + event.error)
        }
    };

    return (
        <div>
            <MicButton listening={micListening} onClick={toggleListen} />
            <PlayButton source={blobURL} autoPlay={true} disabled={!blobURL} />
        </div>
    )
}

export default VoiceRecorder;
