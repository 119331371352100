import React, { Fragment, useState, useEffect, useRef } from 'react';
import { InputGroup, FormControl, Button, Row, Col } from 'react-bootstrap';
import { faSearch, faArrowUp, faArrowDown, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { Treebeard, decorators } from 'react-treebeard';
import _ from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { filterTree, expandFilteredNodes, filterOutEmptyObj } from '../../utils/filterTree';
import { visibleTreeToArray, getNodeById, untoggleChildren, 
         getSiblings, replaceFromData, toggleAllParents, 
         removeFoundClass } from '../../utils/traverseTree';
import { addNode, addNodeAsSibling, copyNode, deleteNode, editTitle, loadData, renameNodes } from '../../redux/actions/DataActions';
import { loadHelperData, addHelperRoot } from '../../redux/actions/HelperDataActions';
import userActions from '../../redux/actions/UserActions';
import { checkVisible } from '../../utils/common';
import NodeViewer from '../NodeViewer';
import Header from './Header';
import HelperTree from '../helperTree/HelperTree';
import Logout from '../auth/Logout';
import PeriodSelector from '../periodSelector/PeriodSelector';
import styles from '../styles';
import './Tree.css';

const Tree = () => { 
    const globalData = useSelector((state) => state.data);
    const helperData = useSelector((state) => state.helperData);
    const user = useSelector((state) => state.user);
    const [data, setData] = useState(globalData.data);
    const [arrayData, setArrayData] = useState({ data: undefined, current: undefined });
    const [cursor, setCursor] = useState(undefined);
    const [cursorSiblings, setCursorSiblings] = useState(undefined);
    const [copying, setCopying] = useState(false);
    const [moving, setMoving] = useState(false);
    const [copyData, setCopyData] = useState(undefined);
    const [rootData, setRootData] = useState({ isSet: false, data: undefined });
    const [replace, setReplace] = useState('');
    const inputSearch = useRef(null);
    const dispatch = useDispatch();

    useHotkeys('del', handleDeleteNode, undefined, [cursor]);
    useHotkeys('ctrl+c, command+c', handleStartCopying, undefined, [cursor, copying, copyData]);
    useHotkeys('ctrl+x, command+x', handleStartMoving, undefined, [cursor, copying, copyData, moving]);
    useHotkeys('ctrl+v, command+v', handlePaste, undefined, [cursor, copying, copyData, moving])
    useHotkeys('=', handleAddNodeAsChild, undefined, [cursor]);
    useHotkeys('-', handleAddNodeAsSibling, undefined, [cursor]);

    useHotkeys('ctrl+s', e => {
        e.preventDefault();
        inputSearch.current.focus();
    }, undefined, [inputSearch]);

    useHotkeys('up', e => {
        if (arrayData.current > 0) {
            let node = arrayData.data[arrayData.current - 1];
            if (cursor) {
                cursor.active = false;
            }

            if (checkVisible(document.getElementById(`node-${node.id}`))) e.preventDefault();

            node.active = true;
    
            setCursor(node);
            setData([...data]);
            const siblings = getSiblings(data, node);
            setCursorSiblings(siblings);
        }
    }, undefined, [arrayData, data, cursor]);

    useHotkeys('down', e => {
        if (arrayData.current < arrayData.data.length - 1) {
            let node = arrayData.data[arrayData.current + 1];
            if (cursor) {
                cursor.active = false;
            }

            if (checkVisible(document.getElementById(`node-${node.id}`))) e.preventDefault();

            node.active = true;
    
            setCursor(node);
            setData([...data]);
            const siblings = getSiblings(data, node);
            setCursorSiblings(siblings);
        }
    }, undefined, [arrayData, data, cursor]);

    useHotkeys('right', () => {
        if (cursor.children) cursor.toggled = true; 
        setData([...data]);
    }, undefined, [cursor, data]);

    useHotkeys('left', () => { 
        if (cursor.children) cursor.toggled = false; 
        setData([...data]);
    }, undefined, [cursor, data]);

    useHotkeys('esc', handleRootSet, undefined, [rootData, cursor]);

    useHotkeys('alt+up', handleMoveNodeUp, undefined, [data, cursor, cursorSiblings]);
    useHotkeys('alt+down', handleMoveNodeDown, undefined, [data, cursor, cursorSiblings]);

    useEffect(() => {        
        if (globalData.lastId) {
            let n;
            n = getNodeById(globalData.data, globalData.lastId);
            if (cursor) cursor.active = false;
            n.active = true;
            setCursor(n);
            setData(globalData.data);
        } else {
            setData(globalData.data);
        }
    }, [globalData]);

    useEffect(() => {
        dispatch(userActions.getUser());
        dispatch(loadData());
        dispatch(loadHelperData());
    }, []);

    useEffect(() => {
        if (data) setArrayData({...arrayData, ...visibleTreeToArray(data)});
    }, [data]);

    function onToggle(node, toggled) {
        if (node.children) {
            node.toggled = toggled;
            if (!toggled) {
                untoggleChildren(node.children);
            }
        }

        setData([...data]);
    };

    function onSelect(node) {
        if (cursor) {
            cursor.active = false;
            if (!includes(cursor.children, node)) {
                cursor.selected = false;
            }
        }

        node.selected = true;
        node.active = true;

        setCursor(node);
        setData([...data]);
        const siblings = getSiblings(data, node);
        setCursorSiblings(siblings);
    };

    function onFilterMouseUp({target: {value}}) {
        const filter = value.trim();
        if (!filter) {
            if (rootData.isSet) {
                removeFoundClass(rootData.data);
                if (cursor) setCursor(toggleAllParents(rootData.data, cursor.id));
                setData(rootData.data);
            }
            else {
                if (cursor) setCursor(toggleAllParents(globalData.data, cursor.id));
                setData(globalData.data);
            }
            return;
        }
        let filtered = rootData.isSet ? filterTree(_.cloneDeep(rootData.data), filter) : filterTree(_.cloneDeep(globalData.data), filter);
        filtered = filterOutEmptyObj(filtered);
        filtered = expandFilteredNodes(filtered, filter);
        setData(filtered);
    };

    function handleReplace() {
        if (inputSearch.current.value) {
            const nodesToReplace = replaceFromData(data, inputSearch.current.value, replace);
            dispatch(renameNodes(nodesToReplace));
            setReplace('');
            inputSearch.current.value = '';
            setCursor(undefined);
        }
    }

    function onChangeTitle(title) {
        dispatch(editTitle(cursor.id, title));
    };

    function onChangeHasValues(value) {
        cursor.data.has_values = value;
        setData([...data]);
    };

    function handleAddNodeAsChild() {
        if (cursor) cursor.active = false; 
        dispatch(addNode(cursor.id));
    };

    function handleAddNodeAsSibling() {
        if (cursor) cursor.active = false; 
        dispatch(addNodeAsSibling(cursor.id));
    };

    function handleDeleteNode() {
        if(window.confirm(`Czy na pewno chcesz usunąć '${cursor.data.name}'?`)) {
            dispatch(deleteNode(cursor.id));
            setCursor(undefined);
        }
    };

    function handleStartCopying() {
        setCopying(true);
        setMoving(false);
        setCopyData(cursor);
        dispatch(addHelperRoot(cursor));
    };

    function handleStartMoving() {
        setCopying(true);
        setMoving(true);
        setCopyData(cursor);
    }

    function handlePaste() {
        if (cursor) cursor.active = false; 
        if (copyData) {
            if (moving) {
                dispatch(copyNode(copyData, cursor.id, true));
                setCopying(false);
                setMoving(false);
                setCopyData(undefined);
            } else {
                dispatch(copyNode(copyData, cursor.id));
            } 
        }
    }

    function cancelCopying() {
        setCopying(false);
        setMoving(false);
    }

    function handleRootSet() {
        if (rootData.isSet) {
            setCursor(toggleAllParents(globalData.data, cursor.id));
            setData(globalData.data);
            setRootData({data: undefined, isSet: !rootData.isSet});
        } else {
            setData([cursor]);
            setRootData({data: [cursor], isSet: !rootData.isSet});
        }
    }

    function handleAddFromHelperTree(node) {
        if (!cursor) alert('Należy wybrać cechę w głównym drzwie przed wstawieniem.')
        else {
            dispatch(copyNode(node.children[0], cursor.id));
        }
    }

    function handleMoveNodeUp() {
        if (cursorSiblings.nodePos > 0) {
            fetch('/tree/swap-nodes/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ 
                    nodeId1: cursorSiblings.siblings[cursorSiblings.nodePos - 1].id, 
                    nodeId2: cursorSiblings.siblings[cursorSiblings.nodePos].id,
                    helper: false
                }),
            });
            if (!cursorSiblings.root) {
                const temp = cursorSiblings.siblings[cursorSiblings.nodePos - 1];
                cursorSiblings.siblings[cursorSiblings.nodePos - 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
                cursorSiblings.siblings[cursorSiblings.nodePos] = temp;
                cursorSiblings.nodePos -= 1;
                setData([...data]);
            } else {
                const temp = data[cursorSiblings.nodePos - 1];
                data[cursorSiblings.nodePos - 1] = data[cursorSiblings.nodePos];
                data[cursorSiblings.nodePos] = temp;

                const temp1 = cursorSiblings.siblings[cursorSiblings.nodePos - 1];
                cursorSiblings.siblings[cursorSiblings.nodePos - 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
                cursorSiblings.siblings[cursorSiblings.nodePos] = temp1;
                cursorSiblings.nodePos -= 1;
                setData([...data]);
            }
        }
    }

    function handleMoveNodeDown() {
        if (cursorSiblings.nodePos < cursorSiblings.siblings.length - 1) {
            fetch('/tree/swap-nodes/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ 
                    nodeId1: cursorSiblings.siblings[cursorSiblings.nodePos].id, 
                    nodeId2: cursorSiblings.siblings[cursorSiblings.nodePos + 1].id,
                    helper: false
                }),
            });
            if (!cursorSiblings.root) {
                const temp = cursorSiblings.siblings[cursorSiblings.nodePos + 1];
                cursorSiblings.siblings[cursorSiblings.nodePos + 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
                cursorSiblings.siblings[cursorSiblings.nodePos] = temp;
                cursorSiblings.nodePos += 1;
                setData([...data]);
            } else {
                const temp = data[cursorSiblings.nodePos + 1];
                data[cursorSiblings.nodePos + 1] = data[cursorSiblings.nodePos];
                data[cursorSiblings.nodePos] = temp;

                const temp1 = cursorSiblings.siblings[cursorSiblings.nodePos + 1];
                cursorSiblings.siblings[cursorSiblings.nodePos + 1] = cursorSiblings.siblings[cursorSiblings.nodePos];
                cursorSiblings.siblings[cursorSiblings.nodePos] = temp1;
                cursorSiblings.nodePos += 1;
                setData([...data]);
            }
        }
    } 

    function handlePeriodChange(period) {
        dispatch(loadData(period));
    }

    return (
        data 
        ? 
        <Fragment>
            <Row className="flex-row-reverse w-100 mr-0">
                <Logout />
            </Row>
            <Row className="mr-0 mb-3">
                <Col className="col-sm-7">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="search">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Szukaj..."
                            aria-label="search"
                            aria-describedby="search"
                            onKeyUp={onFilterMouseUp}
                            ref={inputSearch}
                        />
                    </InputGroup>
                </Col>
                <Col className="col-sm-4">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="replace">
                                <FontAwesomeIcon icon={faExchangeAlt} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Zamień na..."
                            aria-label="replace"
                            aria-describedby="replace"
                            value={replace}
                            onChange={e => setReplace(e.target.value)}
                        />
                    </InputGroup>
                </Col>
                <Col className="col-sm-1">
                    <Button className="w-100 h-100" variant="dark" size="sm" onClick={handleReplace}>Zamień</Button>
                </Col>
            </Row>
            {
                user.currentUser.username === 'admin' &&
                <PeriodSelector onChange={handlePeriodChange} />
            }
            <div >
                <Button variant="dark" size="sm" className="mb-2 mt-0" title="-" onClick={handleAddNodeAsSibling}>Dodaj</Button>
                <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="=" onClick={handleAddNodeAsChild}>Utwórz podcechę</Button>
                <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="Delete" onClick={handleDeleteNode}>Usuń</Button>
                { !copying ? <Button variant="dark" size="sm" className="mb-2 mt-0 ml-2" title="Ctrl+C" onClick={handleStartCopying}>Kopiuj</Button> : <></> }
                { copying ? <Button variant="dark" size="sm" className="mb-2 mt-0 ml-2" title="Ctrl+V" onClick={handlePaste}>Wklej</Button> : <></> }
                { copying ? <Button variant="danger" size="sm" className="mb-2 mt-0 ml-1" onClick={cancelCopying}>X</Button> : <></> }
                {!!cursor && <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="Alt+Up" onClick={handleMoveNodeUp}>
                    <FontAwesomeIcon 
                        icon={faArrowUp} 
                        size="lg" 
                    />
                </Button>}
                {!!cursor && <Button variant="dark" size="sm" className="mb-2 mt-0 ml-1" title="Alt+Down" onClick={handleMoveNodeDown}>
                    <FontAwesomeIcon 
                        icon={faArrowDown} 
                        size="lg" 
                    />
                </Button>}
            </div>
            <div className="pr-0 pt-0 pl-0" style={{...styles.component, width: '40%'}}>
                <div className="big-tree">
                    <Treebeard
                        data={data}
                        onToggle={onToggle}
                        onSelect={onSelect}
                        decorators={{...decorators, Header}}
                    />
                </div>
                { helperData.length > 0 && <HelperTree data={helperData} handleAddToMainTree={handleAddFromHelperTree} /> }
            </div>
            <div className="pt-0" style={{...styles.component, width: '60%'}} >
                { !!cursor && 
                    <NodeViewer 
                        key={cursor.id} 
                        node={cursor} 
                        onChangeTitle={onChangeTitle}
                        onChangeHasValues={onChangeHasValues}
                /> }
            </div>
        </Fragment> 
        : <></>
    );
}

export default Tree;
