import React, { useState, useEffect, useRef, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import './MicButton.css';

const PlayButton = props => {
    const isInitialMount = useRef(true);
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        if (props.autoPlay) {
            if (isInitialMount.current) isInitialMount.current = false;
            else setPlaying(true);
        }
    }, [props.source])

    const handleClick = e => {
        e.stopPropagation();
        if (props.source) {
            setPlaying(!playing);
        }
    }

    const onEnded = () => {
        setPlaying(!playing);
    }

    return (
        <Fragment>
            <FontAwesomeIcon 
                icon={playing ? faPause : faPlay} 
                className={props.disabled ? '' : 'cursor-pointer'} 
                size="lg"
                style={props.disabled ? {color: '#d7d7d761'} : {color: '#d7d7d7'}}
                onClick={props.disabled ? e => e.stopPropagation() : handleClick}
            />
            <ReactPlayer className='d-none' url={props.source} playing={playing} onEnded={onEnded} />
        </Fragment>
    )
}

export default PlayButton;