import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import './MicButton.css';

const MicButton = props => {
    return (
        <FontAwesomeIcon 
            className={`mr-2 cursor-pointer ${props.listening ? 'listening' : ''}`} 
            icon={faMicrophone} 
            size="lg"
            style={{color: '#d7d7d7'}}
            onClick={props.onClick}  
        />
    )
}

export default MicButton;
