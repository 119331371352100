import React, {useState, useEffect} from 'react';
import Collapsible from 'react-collapsible';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import QuestionTrigger from './QuestionTrigger';
import Answer from './Answer';
import b64toBlob from '../utils/b64toBlob';
import './QuestionsAndAnswers.css';

const QuestionsAndAnswers = props => {
    const [questions, setQuestions] = useState(undefined); 
    const [triggerDisabled, setTriggerDisabled] = useState(false);

    useEffect(() => {
        fetch('/tree/get-questions-and-answers/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node}),
        })
        .then(res => res.json())
        .then(json => { 
            setQuestions(json);
        })
    }, []);

    function handleAddQuestion() {
        fetch('/tree/add-question/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({node_id: props.node}),
        })
        .then(res => res.json())
        .then(newId => {
            setQuestions([...questions, {id: newId, value: '', audio: null, answers: []}]);
        });
    }

    function handleAddAnswer(e) {
        const questionId = parseInt(e.currentTarget.id.split('-')[1]);
        fetch('/tree/add-answer/', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({question_id: questionId}),
        })
        .then(res => res.json())
        .then(newId => {
            const copy = _.cloneDeep(questions);
            for (let i = 0; i<copy.length; i++) {
                if (copy[i].id === questionId) {
                    copy[i].answers.push({id: newId, value: '', audio: null})
                    break;
                };
            }
            setQuestions(copy);
        });
    }

    function handleQuestionDelete(questionId) {
        let i;
        const copy = [...questions];
        for (i = 0; i<questions.length; i++) {
            if (questions[i].id === questionId) break;
        }
        copy.splice(i, 1);
        setQuestions(copy);
    }

    function handleAnswerDelete(questionId, answerId) {
        const copy = _.cloneDeep(questions);
        for (let i = 0; i<copy.length; i++) {
            if (copy[i].id === questionId) {
                for (let j = 0; j<copy[i].answers.length; j++) {
                    if (copy[i].answers[j].id === answerId) {
                        copy[i].answers.splice(j, 1);
                        break;
                    }
                }
            }
        }
        setQuestions(copy);
    }


    return (
        <div className="text-center overflow-y">
            { questions 
            ? questions.map((question, i) => {
                return (
                    <Collapsible 
                        key={question.id} 
                        transitionTime={150}
                        triggerDisabled={triggerDisabled} 
                        trigger={
                            <QuestionTrigger 
                                disableTrigger={() => setTriggerDisabled(true)} 
                                enableTrigger={() => setTriggerDisabled(false)} 
                                questionId={question.id} 
                                question={question.value}
                                audio={question.audio ? b64toBlob(question.audio) : undefined}
                                onDelete={handleQuestionDelete}
                            />}>
                        { question.answers.map((answer, i) => (
                            <Answer 
                                key={answer.id} 
                                questionId={question.id} 
                                answerId={answer.id} 
                                answer={answer.value}
                                audio={answer.audio ? b64toBlob(answer.audio) : undefined}
                                satisfaction={answer.satisfaction} 
                                onDelete={handleAnswerDelete} 
                            />
                        ))}
                        <Button 
                            variant="dark" 
                            size="sm" 
                            className="mt-1" 
                            id={`addquestion-${question.id}`} 
                            onClick={handleAddAnswer}>
                            Dodaj odpowiedź
                        </Button>
                    </Collapsible>
                )
            })
            : <></> }

            <Button variant="dark" size="sm" className="mt-2" onClick={handleAddQuestion}>Dodaj pytanie</Button>
        </div>
    )
}

export default QuestionsAndAnswers;