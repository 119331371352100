import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeh, faFrown, faSmile } from '@fortawesome/free-solid-svg-icons'
import './Satisfaction.css';

const Satisfaction = props => {
    const [value, setValue] = useState(props.satisfaction);

    return (
        <div>
            <FontAwesomeIcon 
                className={ value === -1 ? 'Satisfaction-selected' : 'Satisfaction-not-selected'} 
                icon={faFrown} 
                size="2x" 
                onClick={() => {
                    setValue(-1);
                    props.onChange(-1);
                }}
            />
            <FontAwesomeIcon 
                className={ value === 0 ? 'Satisfaction-selected' : 'Satisfaction-not-selected'}
                icon={faMeh} 
                size="2x" 
                onClick={() => {
                    setValue(0);
                    props.onChange(0)
                }}
            />
            <FontAwesomeIcon 
                className={ value === 1 ? 'Satisfaction-selected' : 'Satisfaction-not-selected'} 
                icon={faSmile} 
                size="2x" 
                onClick={() => {
                    setValue(1);
                    props.onChange(1);
                }}
            />
        </div>
    )
}

export default Satisfaction;